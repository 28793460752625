@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .p-mainVisual {
  width: 100%;
  height: 714px;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    #top .p-mainVisual {
      height: 340px; } }
  @media screen and (max-width: 568px) {
    #top .p-mainVisual {
      height: 240px; } }
  #top .p-mainVisual__bg {
    height: 100%;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position-x: 100px; }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__bg {
        width: calc(100% - 30px); } }
  #top .p-mainVisual__swipe {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 60%;
    background-color: #FFFFFF;
    line-height: 0; }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__swipe {
        top: 0px;
        transform: none;
        width: 100%; } }
    #top .p-mainVisual__swipe-inner {
      width: 100%;
      height: 100%; }
    #top .p-mainVisual__swipe-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0; }
    #top .p-mainVisual__swipe-item {
      width: 100%;
      height: 100vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      clip-path: circle(71.5% at 74.5% 50%); }
      @media screen and (max-width: 1168px) {
        #top .p-mainVisual__swipe-item {
          clip-path: circle(82.5% at 99.5% 50%); } }
      @media screen and (max-width: 968px) {
        #top .p-mainVisual__swipe-item {
          clip-path: circle(73.5% at 99.5% 50%); } }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__swipe-item {
          clip-path: none; } }
  #top .p-mainVisual__logobox {
    max-width: 356px;
    margin: 0 auto; }
    #top .p-mainVisual__logobox img {
      width: 100%; }
    @media screen and (max-width: 1024px) {
      #top .p-mainVisual__logobox {
        max-width: 300px; } }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__logobox {
        top: auto;
        bottom: 54px;
        transform: none; } }
    @media screen and (max-width: 568px) {
      #top .p-mainVisual__logobox {
        left: 0;
        padding: 0 15px 0 30px; } }
  #top .p-mainVisual__catch {
    position: absolute;
    top: 50%;
    left: 23%;
    transform: translate(-50%, -50%);
    max-width: 605px;
    width: 100%;
    color: #FFFFFF;
    text-align: center; }
    @media screen and (max-width: 1168px) {
      #top .p-mainVisual__catch {
        left: 227px; } }
    @media screen and (max-width: 1024px) {
      #top .p-mainVisual__catch {
        max-width: 400px; } }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__catch {
        top: auto;
        bottom: -103px;
        left: 211px;
        /* transform: none; */
        padding: 20px; } }
    @media screen and (max-width: 568px) {
      #top .p-mainVisual__catch {
        top: auto;
        bottom: -78px;
        left: 140px;
        padding: 20px; } }
    #top .p-mainVisual__catch-heading {
      font-size: 32px;
      margin-bottom: 18px;
      color: #000; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__catch-heading {
          font-size: 28px; } }
      @media screen and (max-width: 568px) {
        #top .p-mainVisual__catch-heading {
          font-size: 24px; } }
      #top .p-mainVisual__catch-heading small {
        font-size: 32px; }
        @media screen and (max-width: 1024px) {
          #top .p-mainVisual__catch-heading small {
            font-size: 26px; } }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__catch-heading small {
            font-size: 22px; } }
    #top .p-mainVisual__catch-desc {
      font-size: 18px;
      line-height: 1.5; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__catch-desc {
          font-size: 16px; } }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__catch-desc {
          font-size: 14px; } }
  #top .p-mainVisual__scroll {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 105px;
    right: -55px;
    transform: rotate(90deg); }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__scroll {
        bottom: 125px;
        right: -68px; } }
    #top .p-mainVisual__scroll-txt {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.05em;
      display: inline-block;
      margin-right: 12px; }
    #top .p-mainVisual__scroll-bar {
      display: inline-block;
      width: 100px;
      height: 2px;
      background-color: #CCCCCC;
      position: relative;
      overflow: hidden; }
      #top .p-mainVisual__scroll-bar:before {
        content: '';
        width: 50%;
        height: 2px;
        position: absolute;
        top: 0;
        left: -50%;
        display: inline-block;
        background-color: #82A408; }

#top #wrapper {
  padding: 0 0; }
  @media screen and (max-width: 1168px) {
    #top #wrapper {
      padding: 60px 0 0; } }
  @media screen and (max-width: 768px) {
    #top #wrapper {
      padding: 40px 0 0; } }

#top #newslist {
  padding-bottom: 0; }
  @media screen and (max-width: 768px) {
    #top #newslist .c-newslist__wrap {
      background-color: #FAFAFA;
      padding: 15px 0 30px; } }
  @media screen and (max-width: 768px) {
    #top #newslist .c-newslist dl dt {
      margin-bottom: 5px; } }
  #top #newslist .c-newslist dl dd {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

@media screen and (max-width: 1168px) {
  #top .l-section_feature {
    padding-top: 60px; } }

#top .p-feature-sub {
  font-size: 18px;
  display: block;
  color: #82A408;
  font-weight: bold;
  letter-spacing: 0 !important; }

@media screen and (max-width: 1168px) {
  #top .l-section_flow {
    padding-top: 60px; } }

#top .c-card01__body_feature {
  padding: 0; }

#top .c-card01__thumb_feature {
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: contain !important;
  width: 100%;
  padding-top: 67.26462% !important; }

#top .c-card01__inner_feature {
  box-shadow: none !important; }

#top .p-solution-comment {
  text-align: right;
  margin-top: 20px; }

#top .p-bg-blue {
  background-color: #FAFAFA; }
  @media screen and (max-width: 1168px) {
    #top .p-bg-blue {
      padding-top: 90px; } }

#top .p-text-box-position {
  margin-top: -170px !important; }
  @media screen and (max-width: 768px) {
    #top .p-text-box-position {
      margin-top: 0 !important; } }

@media screen and (max-width: 768px) {
  #top .p-flex-reverse {
    flex-direction: column-reverse !important; } }

#top .p-about .l-col2__detail {
  position: relative; }
  #top .p-about .l-col2__detail:before {
    content: '';
    display: block;
    background: url("/inc/image/top/img_shovel.jpg") no-repeat center/contain;
    width: 940px;
    height: 511px;
    position: absolute;
    top: 50%;
    left: 380px;
    transform: translateY(-50%);
    z-index: -1; }
    @media screen and (max-width: 1024px) {
      #top .p-about .l-col2__detail:before {
        left: 50px; } }
    @media screen and (max-width: 768px) {
      #top .p-about .l-col2__detail:before {
        left: 80px;
        width: 658px;
        height: 357.7px; } }
    @media screen and (max-width: 568px) {
      #top .p-about .l-col2__detail:before {
        left: 80px;
        width: 470px;
        height: 255.5px; } }

#top .p-reason .l-col2__detail {
  position: relative; }
  #top .p-reason .l-col2__detail:before {
    content: '';
    display: block;
    background: url("/inc/image/top/img_loader.jpg") no-repeat center/contain;
    width: 968px;
    height: 519px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: -1; }
    @media screen and (max-width: 768px) {
      #top .p-reason .l-col2__detail:before {
        width: 658px;
        height: 357.7px; } }
    @media screen and (max-width: 568px) {
      #top .p-reason .l-col2__detail:before {
        width: 484px;
        height: 259.5px; } }

.p-news-abso {
  position: absolute;
  border-bottom: 0;
  left: 0;
  right: 0;
  bottom: 50px; }
  @media screen and (max-width: 1168px) {
    .p-news-abso {
      bottom: 0px; } }

@media screen and (max-width: 768px) {
  .p-news-pc-only {
    display: none; } }

.p-news-sp-only {
  display: none; }
  @media screen and (max-width: 768px) {
    .p-news-sp-only {
      display: block; } }

.p-heading-br {
  display: none; }
  @media screen and (max-width: 468px) {
    .p-heading-br {
      display: block; } }

.p-c-btn-catch {
  background-color: #FF8108;
  border: 1px solid #FF8108; }
  @media screen and (max-width: 568px) {
    .p-c-btn-catch {
      width: 85%; } }

.p-c-btn-catch:hover {
  color: #FF8108; }

@media screen and (max-width: 768px) {
  .p-catch-pc {
    display: none; } }

.p-catch-sp {
  display: none; }
  @media screen and (max-width: 768px) {
    .p-catch-sp {
      display: block;
      max-width: 550px;
      margin: 0 auto; } }

.p-catch-sp-imgbox {
  max-width: 240px;
  margin: 0 auto; }
  .p-catch-sp-imgbox img {
    width: 100%; }

@media screen and (max-width: 568px) {
  .p-catch-sp-heading {
    text-align: center;
    padding-top: 15px; } }

@media screen and (max-width: 768px) {
  .p-pc-only {
    display: none; } }

.p-tab-only {
  display: none; }
  @media screen and (max-width: 768px) {
    .p-tab-only {
      display: block;
      padding-bottom: 40px !important; } }
  @media screen and (max-width: 568px) {
    .p-tab-only {
      display: none; } }

.p-sp-only {
  display: none; }
  @media screen and (max-width: 568px) {
    .p-sp-only {
      display: block;
      padding-bottom: 40px !important; } }
